<template>
  <div style="height: 100%; width: 100%" ref="panel"></div>
</template>
<script>

import * as echarts from 'echarts'

export default {

  props: {
    color: String,
    value: Number
  },

  data () {
    return {
      echartsInstance: null
    }
  },

  mounted () {
    this.init()
    const that = this
    window.addEventListener('resize', this.debounce(function () {
      that.init()
    }, 1000))
  },

  beforeDestroy () {

  },

  watch: {
    value () {
      this.init()
    }
  },

  methods: {

    debounce (fn, delay) {
      let timer = null
      return function () {
        if (timer) clearTimeout(timer)
        timer = setTimeout(fn, delay)
      }
    },

    init () {
      this.echartsInstance = echarts.init(this.$refs.panel)
      this.echartsInstance.resize()
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            color: [this.color || '#B94AFF'],
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 8
              }
            },
            splitLine: {
              show: false,
              distance: 0
            },
            axisTick: {
              show: false
            },
            radius: '100%',
            axisLabel: {
              show: false,
              distance: 0
            },
            data: [
              {
                value: this.value,
                detail: {
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              width: 10,
              height: 14,
              fontSize: 14,
              color: '#00F9FF',
              formatter: '{value}%'
            }
          }
        ]
      }
      this.echartsInstance.setOption(option)
    }
  }
}
</script>
